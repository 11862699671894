:root {
  // -----------------  Gradient Colors -------------------

  // -----------------  dark Colors -------------------

  --dark-1: #000;
  --dark-2: #474752;
  --dark-3: #6b6a6e;
  --dark-4: #49e1fb;
  --dark-5: #7af4fc;
  --dark-6: #22222e;
  --dark-7: #22222f;
  --dark-black-500: #00000080;

  // -----------------  light Colors -------------------

  --light-1: #c3c3c3;
  --light-2: #d3d3d6;
  --light-3: #96a7af;
  --light-4: #f1f5f7;

  // -----------------  Tertiary Colors -------------------

  --tertiary-1: #f26d0c;
  --tertiary-2: #fdd649;
  --tertiary-25: #fdd64980;
  --tertiary-3: #3dd598;
  --tertiary-4: #ffab48;
  --tertiary-5: #ff575f;
  --tertiary-6: #ff0303;

  // -----------------  white Colors -------------------
  --white-100: #fff;
  --white-400: #ffffff66;
  --white-500: #ffffff80;
  --white-700: #ffffffb3;
  --white-900: #ffffffe6;
}

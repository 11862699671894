// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$ef-b2b-portal-primary: mat.define-palette(mat.$indigo-palette);
$ef-b2b-portal-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$ef-b2b-portal-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$ef-b2b-portal-theme: mat.define-light-theme(
  (
    color: (
      primary: $ef-b2b-portal-primary,
      accent: $ef-b2b-portal-accent,
      warn: $ef-b2b-portal-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($ef-b2b-portal-theme);

/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
@import "bootstrap/scss/bootstrap";

@import "./assets/scss/login.scss";

/* You can add global styles to this file, and also import other style files */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;500;700;800;900&display=swap");

// variable color
@import ".././src/assets/scss/variables.scss";

* {
  margin: 0;
  padding: 0;
  // font-family: 'Poppins', sans-serif;
}

body {
  background-color: #09070e;
  color: #fff;
  height: calc(100vh - 72px);
  overflow-x: hidden;
}

body,
html {
  @include transition(all 0.5s);
  font-family: "Poppins", sans-serif !important;
  font-size: 14px;
  height: 100%;
}

body [class^="mat"],
body [class*=" mat-"],
body [class^="mdc"] {
  font-family: "Poppins", sans-serif !important;
}

body [class*="material-icons"] {
  font-family: "Material Icons Two Tone" !important;
}

/** Scroll Bar */

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 0;
}

/* Track */
::-webkit-scrollbar-track {
  background: #09070e;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #191924;
  border-radius: 3px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(180deg, #222230 0%, #22222d 100%);
}

/** B2B Portal Button */
.btn {
  &.ef-b2b-primary-btn {
    border-radius: 8px;
    background: var(--CTA, linear-gradient(270deg, #27d4fa 0%, #7af4fc 100%));

    color: #31313f;
    text-align: center;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    text-transform: uppercase;

    padding: 8px 20px;

    &:hover {
      color: initial;
    }
  }

  &.ef-b2b-secondary-btn {
    border-radius: 8px;
    background: #191921;

    padding: 16px 20px;

    span {
      background: var(
        --Brand-Primary,
        linear-gradient(270deg, #27d4fa 0%, #7af4fc 100%)
      );
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;

      text-align: center;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 16px;
    }

    &:hover {
      color: initial;
    }
  }

  &.ef-b2b-link-btn {
    color: #49e1fb;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    &:hover {
      color: #49e1fb;
    }

    &:active {
      color: #49e1fb !important;
      border-color: transparent !important;
      background-color: transparent !important;
    }
  }
}

:not(.btn-check) + .btn:active,
.btn:first-child:active {
  color: initial;
  background-color: initial;
  border-color: initial;
}

/** B2B Portal Button End */

/** B2B form control */

.b2b-form-ctrl-wrapper {
  .form-label {
    color: #8f8f94;
    font-weight: 400;
    line-height: normal;
  }

  .form-control {
    background-color: transparent;
    border-radius: 12px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    color: #fff;
    height: 52px;

    &::placeholder {
      color: #94939b;
      font-size: 16px;
      font-weight: 400;
      line-height: normal;
    }
  }

  .form-select {
    background-color: transparent;
    border-radius: 12px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    color: #fff;
    --bs-form-select-bg-img: url("./assets/icons/student/down-arrow-icon.svg");
    height: 52px;

    option {
      border-radius: 5px;
      border: 1px solid #474752;
      background: #181822;
      box-shadow: 0px 3px 9px 0px rgba(64, 77, 79, 0.25);
    }
  }

  .b2b-select-box-placeholder {
    position: absolute;
    left: 15px;
    top: 15px;
    color: #8f8f94;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: default;
  }

  .input-group {
    .input-group-text {
      background-color: transparent;
      border: 1px solid rgba(255, 255, 255, 0.3);
      border-right: 0;
      border-radius: 12px;
      color: #fff;
    }

    .form-control {
      border-left: 0;
      margin-left: 0 !important;
    }
  }
}
.ef-b2b-add-btn {
  display: flex;
  padding: 4px 10px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: 8px;
  border: 1px solid #49e1fb;
  color: #49e1fb;
}
.cursorPointer {
  cursor: pointer;
}

/** B2B form control end */

.header-block {
  display: flex;
  justify-content: space-between;
  padding: 15px 0 15px 0;
  border-bottom: 1px solid rgba($color: #fff, $alpha: 0.2);

  .page-caption {
    font-size: 24px;
    font-weight: 700;
    line-height: normal;
    opacity: 0.7;
  }
}

.page-caption-2 {
  font-size: 20px;
  font-weight: 700;
  line-height: normal;
}

.photo-block {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  img {
    border-radius: 8.022px;
    background: #d9d9d9;
    padding: 6px;
  }

  .img-info-block {
    .lbl {
      font-size: 14px;
      font-weight: 400;
      line-height: normal;
    }

    .size-info {
      color: #8f8f94;
      font-size: 10px;
      font-weight: 400;
      line-height: normal;
    }

    .ef-b2b-link-btn {
      font-size: 16px;
    }
  }
}

.section-heading {
  font-size: 20px;
  font-weight: 500;
  line-height: normal;
}

.required {
  color: #f00;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.ef-disabled {
  pointer-events: none;
  cursor: default;
  opacity: 0.5;
  margin-right: 8px !important;
}

.b2b-error-text {
  color: red;
  font-size: 12px;
}
.b2b-empty-container {
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 10px;
  background: #22222e;
  padding: 42px 0;

  .b2b-empty-wrapper {
    width: 241px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .scr-lbl {
      color: var(--ffffff, #fff);
      font-family: Poppins;
      font-size: 20px;
      font-style: normal;
      font-weight: 900;
      line-height: normal;
    }

    .scr-info {
      color: #fff;
      text-align: center;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      // font-weight: 600;
      line-height: normal;
      opacity: 0.4;
    }

    .ef-b2b-primary-btn {
      font-weight: 700;
      font-size: 16px;
      line-height: 16px;
      padding-top: 16px;
      padding-bottom: 16px;
      text-transform: none;
    }
  }
}
